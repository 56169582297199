import type { MetaDescriptor, TypedResponse } from '@remix-run/node'
import { type Params } from '@remix-run/react'
import type { Jsonify } from '@remix-run/server-runtime/dist/jsonify'
import type { EditorRef } from 'react-email-editor'
import type { LoaderFunction, RouteData, UseDataFunctionReturn } from 'remix-typedjson/dist/remix'
import type { ValidationErrorResponseData } from 'remix-validated-form'
import type { SafeParseError, SafeParseSuccess } from 'zod'

export interface TypedV2MetaFunction<
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction> = {}
> {
  (args: {
    data?: UseDataFunctionReturn<Loader>
    parentsData: {
      [k in keyof ParentsLoaders]: UseDataFunctionReturn<ParentsLoaders[k]>
    } & RouteData
    params: Params
    location: Location
  }): MetaDescriptor[]
}

export type ValueOf<T> = T[keyof T]

export const isPojo = (value: unknown): boolean => {
  if (!value || typeof value !== 'object') return false
  return Object.getPrototypeOf(value) === Object.prototype
}

type ValidationErrorResponse = Jsonify<ValidationErrorResponseData> | TypedResponse<ValidationErrorResponseData>

export function isValidationError<T>(response: T | ValidationErrorResponse): response is ValidationErrorResponse {
  return response && typeof response === 'object' && 'fieldErrors' in response
}

export type UnlayerJsonTemplate = Parameters<Parameters<NonNullable<EditorRef['editor']>['exportHtml']>[0]>[0]['design']

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

export type Pojo = Record<string, unknown>

export function isZodSafeParseSuccess<Input, Output>(
  zodResult: SafeParseSuccess<Output> | SafeParseError<Input>
): zodResult is SafeParseSuccess<Output> {
  return zodResult.success
}

export type ItemInArray<T> = T extends Array<infer U> ? NonNullable<U> : never

export type KeyOfMap<M extends Map<unknown, unknown>> = M extends Map<infer K, unknown> ? K : never
